define("discourse/plugins/discourse-multi-reactions/discourse/widgets/discourse-multi-reactions-state-panel", ["exports", "virtual-dom", "discourse/widgets/widget", "discourse/widgets/post"], function (_exports, _virtualDom, _widget, _post) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-multi-reactions-state-panel", {
    tagName: "div.discourse-multi-reactions-state-panel",
    buildKey: attrs => `discourse-multi-reactions-state-panel-${attrs.post.id}`,
    mouseOut() {
      if (!window.matchMedia("(hover: none)").matches) {
        this.callWidgetFunction("scheduleCollapse");
      }
    },
    mouseOver() {
      if (!window.matchMedia("(hover: none)").matches) {
        this.callWidgetFunction("cancelCollapse");
      }
    },
    onChangeDisplayedReaction(reactionId) {
      this.state.displayedReactionId = reactionId;
    },
    defaultState(attrs) {
      return {
        displayedReactionId: attrs.post.reactions && attrs.post.reactions.length ? attrs.post.reactions.sortBy("count").reverse().firstObject.id : null
      };
    },
    html(attrs) {
      if (!attrs.statePanelExpanded) return;
      if (!attrs.post.reactions.length) return;
      const sortedReactions = attrs.post.reactions.sortBy("count").reverse();
      const displayedReaction = attrs.post.reactions.findBy("id", this.state.displayedReactionId) || sortedReactions.firstObject;
      const elements = [];
      if (displayedReaction.users.length > 0) {
        elements.push((0, _virtualDom.h)("div.users", displayedReaction.users.map(user => (0, _post.avatarFor)("tiny", {
          username: user.username,
          template: user.avatar_template
        }))));
      }
      elements.push((0, _virtualDom.h)("div.counters", sortedReactions.map(reaction => this.attach("discourse-multi-reactions-state-panel-reaction", {
        reaction,
        isDisplayed: reaction.id === this.state.displayedReactionId
      }))));
      return [, (0, _virtualDom.h)("div.container", elements)];
    }
  });
});