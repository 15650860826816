define("discourse/plugins/discourse-multi-reactions/discourse/widgets/discourse-multi-reactions-reaction-button", ["exports", "I18n", "discourse-common/lib/icon-library", "virtual-dom", "discourse/widgets/widget", "@ember/runloop"], function (_exports, _I18n, _iconLibrary, _virtualDom, _widget, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let _laterHoverHandlers = {};
  var _default = _exports.default = (0, _widget.createWidget)("discourse-multi-reactions-reaction-button", {
    tagName: "div.discourse-multi-reactions-reaction-button",
    buildKey: attrs => `discourse-multi-reactions-reaction-button-${attrs.post.id}`,
    click() {
      this._cancelHoverHandler();
      if (!this.capabilities.touch) {
        this.callWidgetFunction("toggleLike");
      }
    },
    mouseOver(event) {
      this._cancelHoverHandler();
      if (!window.matchMedia("(hover: none)").matches) {
        _laterHoverHandlers[this.attrs.post.id] = (0, _runloop.later)(this, this._hoverHandler, event, 500);
      }
    },
    mouseOut() {
      this._cancelHoverHandler();
      if (!window.matchMedia("(hover: none)").matches) {
        this.callWidgetFunction("scheduleCollapse");
      }
    },
    buildAttributes(attrs) {
      let title;
      const likeAction = attrs.post.likeAction;
      if (!likeAction) {
        return;
      }
      if (likeAction.canToggle && !likeAction.hasOwnProperty("can_undo")) {
        title = "discourse_multi_reactions.main_reaction.add";
      }
      if (likeAction.canToggle && likeAction.can_undo) {
        title = "discourse_multi_reactions.main_reaction.remove";
      }
      if (!likeAction.canToggle) {
        title = "discourse_multi_reactions.main_reaction.cant_remove";
      }
      return {
        title: _I18n.default.t(title)
      };
    },
    html(attrs) {
      const mainReactionIcon = this.siteSettings.discourse_multi_reactions_like_icon;
      const hasUsedMainReaction = attrs.post.current_user_used_main_reaction;
      const icon = hasUsedMainReaction ? mainReactionIcon : `far-${mainReactionIcon}`;
      return (0, _virtualDom.h)(`button.btn-toggle-reaction.btn-icon.no-text`, [(0, _iconLibrary.iconNode)(icon)]);
    },
    _cancelHoverHandler() {
      const handler = _laterHoverHandlers[this.attrs.post.id];
      handler && (0, _runloop.cancel)(handler);
    },
    _hoverHandler(event) {
      this.callWidgetFunction("cancelCollapse");
      this.callWidgetFunction("toggleReactions", event);
    }
  });
});