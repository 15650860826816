define("discourse/plugins/discourse-multi-reactions/discourse/widgets/discourse-multi-reactions-counter", ["exports", "virtual-dom", "discourse/widgets/widget", "I18n", "@ember/runloop"], function (_exports, _virtualDom, _widget, _I18n, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let _laterHoverHandlers = {};
  var _default = _exports.default = (0, _widget.createWidget)("discourse-multi-reactions-counter", {
    tagName: "div.discourse-multi-reactions-counter",
    buildKey: attrs => `discourse-multi-reactions-counter-${attrs.post.id}`,
    click(event) {
      this._cancelHoverHandler();
      if (!this.capabilities.touch) {
        this.callWidgetFunction("toggleStatePanel", event);
      }
    },
    touchStart(event) {
      if (this.capabilities.touch) {
        this.callWidgetFunction("toggleStatePanel", event);
        event.preventDefault();
        event.stopPropagation();
      }
    },
    mouseOver(event) {
      this._cancelHoverHandler();
      if (!window.matchMedia("(hover: none)").matches) {
        _laterHoverHandlers[this.attrs.post.id] = (0, _runloop.later)(this, this._hoverHandler, event, 500);
      }
    },
    mouseOut() {
      this._cancelHoverHandler();
      if (!window.matchMedia("(hover: none)").matches) {
        this.callWidgetFunction("scheduleCollapse");
      }
    },
    buildAttributes(attrs) {
      return {
        title: _I18n.default.t("discourse_multi_reactions.users_reacted", {
          count: attrs.post.reaction_users_count
        })
      };
    },
    html(attrs) {
      if (attrs.post.reaction_users_count) {
        const count = attrs.post.reaction_users_count;
        if (count <= 0) {
          return;
        }
        return [this.attach("discourse-multi-reactions-list", attrs), (0, _virtualDom.h)("div.reactions-counter", count.toString())];
      }
    },
    _cancelHoverHandler() {
      const handler = _laterHoverHandlers[this.attrs.post.id];
      handler && (0, _runloop.cancel)(handler);
    },
    _hoverHandler(event) {
      this.callWidgetFunction("cancelCollapse");
      this.callWidgetFunction("toggleStatePanel", event);
    }
  });
});