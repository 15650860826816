define("discourse/plugins/discourse-multi-reactions/discourse/models/discourse-multi-reactions-custom-reaction", ["exports", "discourse/lib/ajax", "discourse/models/rest"], function (_exports, _ajax, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CustomReaction = _rest.default.extend({
    init() {
      this._super(...arguments);
      this.__type = "discourse-multi-reactions-custom-reaction";
    }
  });
  CustomReaction.reopenClass({
    toggle(postId, reactionId) {
      return (0, _ajax.ajax)(`/discourse-multi-reactions/posts/${postId}/custom-reactions/${reactionId}/toggle.json`, {
        type: "PUT"
      }).catch(e => {
        bootbox.alert(e.jqXHR.responseJSON.errors.join("\n"));
      });
    }
  });
  var _default = _exports.default = CustomReaction;
});