define("discourse/plugins/discourse-multi-reactions/discourse/widgets/discourse-multi-reactions-list", ["exports", "virtual-dom", "discourse/widgets/raw-html", "discourse/lib/text", "discourse/widgets/widget"], function (_exports, _virtualDom, _rawHtml, _text, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-multi-reactions-list", {
    tagName: "div.discourse-multi-reactions-list",
    html(attrs) {
      const reactions = attrs.post.reactions;
      if (attrs.post.reaction_users_count <= 0) {
        return;
      }
      return [(0, _virtualDom.h)("div.reactions", reactions.sortBy("count").reverse().map(reaction => (0, _virtualDom.h)(`span.reaction.${reaction.id}`, new _rawHtml.default({
        html: (0, _text.emojiUnescape)(`:${reaction.id}:`)
      }))))];
    }
  });
});