define("discourse/plugins/discourse-multi-reactions/discourse/widgets/discourse-multi-reactions-state-panel-reaction", ["exports", "discourse/widgets/raw-html", "discourse/lib/text", "virtual-dom", "discourse/widgets/widget"], function (_exports, _rawHtml, _text, _virtualDom, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-multi-reactions-state-panel-reaction", {
    tagName: "div.discourse-multi-reactions-state-panel-reaction",
    click() {
      if (!this.capabilities.touch) {
        this.sendWidgetAction("onChangeDisplayedReaction", this.attrs.reaction.id);
      }
    },
    touchStart() {
      if (this.capabilities.touch) {
        this.sendWidgetAction("onChangeDisplayedReaction", this.attrs.reaction.id);
      }
    },
    buildClasses(attrs) {
      if (attrs.isDisplayed) {
        return "is-displayed";
      }
    },
    html(attrs) {
      return [(0, _virtualDom.h)("span.count", attrs.reaction.count.toString()), new _rawHtml.default({
        html: (0, _text.emojiUnescape)(`:${attrs.reaction.id}:`)
      })];
    }
  });
});