define("discourse/plugins/discourse-multi-reactions/initializers/discourse-multi-reactions", ["exports", "discourse/lib/plugin-api", "discourse-common/lib/icon-library"], function (_exports, _pluginApi, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  (0, _iconLibrary.replaceIcon)("notification.reaction", "bell");
  function initializeDiscourseMultiReactions(api) {
    api.removePostMenuButton("like");
    api.addKeyboardShortcut("l", () => {
      const button = document.querySelector(".topic-post.selected .discourse-multi-reactions-reaction-button");
      button && button.click();
    });
    api.decorateWidget("post-menu:before-extra-controls", dec => {
      const post = dec.getModel();
      if (!post) {
        return;
      }
      return dec.attach("discourse-multi-reactions-actions", {
        post
      });
    });
  }
  var _default = _exports.default = {
    name: "discourse-multi-reactions",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (siteSettings.discourse_multi_reactions_enabled) {
        (0, _pluginApi.withPluginApi)("0.10.1", initializeDiscourseMultiReactions);
      }
    }
  };
});